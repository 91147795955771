@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('assets/css/style.css');

body {
  background-color: #F3F6FC !important;
  font-size: 14px !important;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif !important;
  cursor: default !important;
}

.black-tool-tip {

  --bs-popover-bg: #000 !important;

  --bs-popover-body-color: #fff !important;

  color: #000;

  .popover {
    background-color: #000;
    color: #fff;

    --bs-popover-bg: #000 !important;

    --bs-popover-body-color: #fff !important;
  }
}

.black-tool-tip .popover-body {
  background-color: black !important;
  color: white !important;
}

.popover .popover-body {

  --bs-popover-bg: #000 !important;

  --bs-popover-body-color: #fff !important;

  color: #fff !important;
}

.my-container {
  margin: 0 60px;
}

.search {
  border-radius: 7px;
  background: #FFF;
  box-shadow: 3px 4px 6px 3px rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .my-container {
    margin: 0 10px;
  }
}

table{
  font-size: 12px;
}

td,  {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}

.my-page {
  position: relative;
  top: 3rem;
}


h1, h2, h3, h4, h5, h6, .h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  color: var(--header-color);
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4, h5, h6 {
  font-size: 18px;
}


.text-color {
  color: #0f0f0f !important;
}

.link-color {
  color: #182664 !important;
}

.secondary-color {
  color: #0064F1 !important;
}

.text-success {
  color: #09742D !important;
}

.text-done {
  color: #6F0E7B !important;
}

.text-muted {
  opacity: 0.7;
}

.list-unstyled {
  list-style: none;
}

.primary-bg {
  background-color: #0f0f0f !important;
}

.dropdown-item {
  font-size: 13px !important;
}

/* ********* Font Sizes ********* */
.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

/* ********* Font Weights ********* */
.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 ! important;
}

/*Forms*/

.form-wrapper {
  //margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  //padding: 8px 12px;
  background: transparent;
  border: 1px solid rgba(41, 41, 41, 0.35);
  border-radius: 5px;
  //overflow: hidden;

  &:focus {
    border: none;
  }

  input,
  select,
  input[type="tel" i],
  textarea {
    flex-grow: 1;
    background-color: transparent;
    //background-color: #F6F8FF;
    border: none;
    color: #8E939E;
    font-weight: 500;
    font-size: 1.2em;

    &::placeholder {
      opacity: 0.2;
    }

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #F6F8FF inset !important;
    -webkit-border: none !important;
    //-webkit-text-fill-color: #8E939E;
    -webkit-text-fill-color: #8E939E;
    transition: background-color 5000s ease-in-out 0s;
  }

  .aside {
    min-width: 30px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;

    mat-icon {
      color: #c5c9cc;

      &:hover {
        //color: $accent;
      }
    }
  }
}

.form-control {
  background: #fff;
  border-radius: 5px;
  border: 1px solid rgba(41, 41, 41, 0.35);
  color: #292929;

  box-shadow: none !important;
  outline: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 110%;

  padding: 10px 12px;
}

.filter {
  margin-bottom: 0.35rem;
  font-size: 14px !important;
  font-weight: 500;
}

/* File Select Card */
.file-select-card-2 {
  border-radius: 6px;
  border: 1px dashed #D1DAE4;
  min-height: 177px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.file-upload-excel-2 {
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.30);
  box-shadow: 2px 2px 13px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background: #fff;
  min-height: 177px;
  padding: 10px;

}

/*.file-upload-excel .file-text{*/
/*  display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center;*/
/*}*/

.file-select-card-2 input {
  display: none;
}

.file-select-card-2 .instructions {
  font-weight: 400;
  opacity: 0.8;
}


.progress {
  background-color: #c3d3fa;
}

.bg-confirming {
  background-color: #182664;
}


.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}


.form-label {
  margin-bottom: 0.75rem;
  font-size: 16px;
}


.form-control.material-form,
.material-form .form-control {
  font-weight: 400;
  font-size: 16px;

  padding: 16px 8px;

  background: transparent;
  border: 0;
  //border-bottom: 1px solid #4b4b4b;
}

.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  //background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid rgba(41, 41, 41, 0.35);
  border-radius: 2px !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::placeholder {
  color: #9F9F9F;

}

footer {
  background-color: #010101;
  color: #fff;
  margin-top: 7rem;
}

/*BUTTONS*/

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  gap: 0.5rem;
  border-radius: 4px !important;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  outline: none !important;
  --bs-btn-active-border-color: transparent;
  transition: transform 0.3s ease-in-out;
}


.btn .icon {
  font-size: 20px;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--primary-color) !important;
  --bs-btn-border-color: var(--primary-color) !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: var(--primary-color) !important;
  --bs-btn-hover-border-color: var(--primary-color) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: var(--primary-color) !important;
  --bs-btn-disabled-border-color: var(--primary-color) !important;
}

.btn-rounded {
  justify-content: center;
  align-items: center;
  border-radius: 20px !important;
  background: rgba(24, 38, 100, 0.13);
}

.btn-light-primary {
  --bs-btn-color: var(--secondary-color) !important;
  --bs-btn-bg: var(--light-bg) !important;
  --bs-btn-border-color: var(--light-bg) !important;
  --bs-btn-hover-color: var(--secondary-color) !important;
  --bs-btn-hover-bg: var(--light-bg) !important;
  --bs-btn-hover-border-color: var(--light-bg) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: var(--secondary-color) !important;
  --bs-btn-active-bg: var(--light-bg);
  --bs-btn-active-border-color: var(--light-bg) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: var(--secondary-color) !important;
  --bs-btn-disabled-bg: var(--light-bg) !important;
  --bs-btn-disabled-border-color: var(--light-bg) !important;
}

.btn-secondary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #4f4f4f !important;
  --bs-btn-border-color: #4f4f4f !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #4f4f4f !important;
  --bs-btn-hover-border-color: #4f4f4f !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #4f4f4f;
  --bs-btn-active-border-color: #4f4f4f !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #4f4f4f !important;
  --bs-btn-disabled-border-color: #4f4f4f !important;
}


.btn-outline-secondary {
  --bs-btn-color: var(--primary-color) !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: var(--primary-color) !important;
  --bs-btn-hover-color: var(--primary-color) !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: var(--primary-color) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: var(--primary-color) !important;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--primary-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-tertiary {
  --bs-btn-color: var(--primary-color) !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: var(--primary-color) !important;
  --bs-btn-hover-color: var(--primary-color) !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: var(--primary-color) !important;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.divider {
  border-top: 0.7px dashed #182664;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer !important;
}


th {
  font-size: 16px;
}

td {
  font-size: 15px;
}

.badge {
  padding: 10px 10px !important;
  border-radius: 3px;
}

.badge-warning {
  color: orange;
  background-color: orange;
}

.badge-success {
  color: #28a745;
  background-color: #28a745;
}

.badge-danger {
  color: #dc3545;
  background-color: #dc3545;
}

.required::after {
  content: " *";
  color: #ff1a1a;
  font-size: 15px;
  font-weight: 500;
}

.optional::after {
  content: ' (optional)';
  font-size: 0.6em;
  opacity: 0.5;
}

.pointer {
  cursor: pointer !important;
}

.mat-mdc-dialog-container .mdc-dialog__title, .mat-mdc-dialog-container .mdc-dialog__content, {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif !important;
  color: unset !important;
}

.cdk-overlay-container {
  z-index: 1056 !important;
}

.not-mapped {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: red;
}

.mapped {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: green;
}

.half-mapped {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #f59e0b;
}


/****************** Dashboard Main Content ******************/
/************************************************************/
.dash-main {
  padding-top: calc(var(--header-height));
  width: calc(100% - var(--sidebar-width));
  margin-left: calc(var(--sidebar-width));

  min-height: 100vh;
}

@media (max-width: 767px) {
  .dash-main {
    width: calc(100%);
    margin-left: 0;
  }
}

/* Main Content */
.dash-content {
  padding: 30px var(--page-padding);

  min-height: calc(100vh - calc(var(--header-height)) - 53px);
}


/********************* Dashboard Footer *********************/
/************************************************************/
.dash-footer {
  background-color: var(--footer-bg-color);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 8px;

  font-weight: 400;
  font-size: var(--footer-font-size);
  line-height: 140%;

  color: var(--footer-text-color);

  padding: 18px var(--page-padding);
}


@media screen and (max-width: 993px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }

}

.bg-pill-search {
  background: #0f7a67;
  margin-bottom: 5px;
  margin-top: 5px;
  color: white;
}


@media only screen and (min-width: 993px) {
  .th-1 {
    height: 70px !important;
  }
}

.tab-navs {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0px;
  gap: 20px;
  width: 100%;
  border-bottom: 3px solid #e4e3e3;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-navs .tab-item {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 2px;
  gap: 10px;

  font-weight: 400;
  font-size: 15px;
  line-height: 120%;

  text-align: center;
  text-transform: capitalize;

  white-space: nowrap;

  border-bottom: 2px solid transparent;

  margin: -1px 0;

  color: #3a3a3a;

  cursor: pointer;
}

.tab-navs .tab-item.active,
.tab-navs .active .tab-item {
  color: #232d42;
  font-weight: 700;

  border-bottom: 2px solid #232d42;
}

.my-content {
  padding: 1.5em;
  box-shadow: 1px 5px 9px 1.5px rgba(0, 0, 0, 0.10);

  border-radius: 10px;
}

.my-content-width {
  max-width: 1100px;
  margin: auto;
}

.disabled-table {
  opacity: 0.5;
  pointer-events: none;
}

.yellow {
  color: #F2994A !important;
}

.intl-tel-input .country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 100%;
  max-height: 170px;
}

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  width: 100% !important;
}

.iti {
  width: 100% !important;
  display: block;
}

.intl-tel-input {
  width: 100% !important;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}

.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.58);
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.scroll-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.scroll-x::-webkit-scrollbar {
  height: 8px;
}

.scroll-x::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scroll-x::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.58);
  border-radius: 4px;
}

.scroll-x::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.modal-scroll {
  overflow-y: auto;
}

//accordion
.accordion-button {
  padding: 7px 7px !important; //var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
}

.dotted-line{
  border-top: 1px dashed black;
}

.cell-count {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 22px;
  min-height: 20px;
  height: 20px;
  width: fit-content;
  padding: 3px 7px;
  flex-shrink: 0;
  background: rgba(0, 100, 241, 0.20);
  border-radius: 4px;
  color: var(--secondary-color, #0064F1);
}

.cell-count-dark {
  border: 0.4px solid rgba(0, 0, 0, 0.30) !important;
  background: rgba(24, 38, 100, 0.20) !important;
  color: #182664 !important;
  font-weight: 600;
}

.info-card {
  padding: 20px;
  border-radius: 10px;
  border: 0.3px solid rgba(0, 100, 241, 0.50);
  background: rgba(0, 100, 241, 0.05);
}

::ng-deep {
  .multiselect-dropdown {
    .selected-item {
      max-width: unset !important;
    }
  }

  .dropdown-list {
    overflow-y: hidden;
  }
}

// 
.bm-card {
  border-radius: 10px;
}

.bm-card-head {
  text-align: center;
  padding: 16px;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bm-card-head span {
  font-weight: 400;
}

.bm-card-body {
  padding: 20px 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bm-card-shadow{
  box-shadow: 1px 5px 9px 0px rgba(0, 0, 0, 0.10)
}

.bm-card-bordered{
  border: 0.5px solid rgba(0, 0, 0, 0.50);
}