:root {
    --hr-color: #e5e9ef;
    --header-color: #000000;
    --primary-color: #0f0f0f;
    --secondary-color: #0064F1;
    --light-bg: #E8F1FE;
    --danger-color: #D62B2B;
    --primary-text-color: #0f0f0f;
    --sidebar-width: 240px;
    --sidebar-overlay-bg-color: rgb(0 0 0 / 23%);
    --sidebar-bg-color: #f8f8f8;
    --sidebar-border-color: #c8d0df;
    --sidebar-text-color: #2e353a;
    --sidebar-padding: 0.5rem;
    --sidebar-font-size: 14px;
    --sidebar-icon-font-size: 19px;
    --sidebar-item-active-bg-color: #fbfbfb;
    --sidebar-item-active-text-color: var(--primary-color);
    --sidebar-item-hover-bg-color: #fbfbfb;
    --sidebar-item-hover-text-color: inherit;
    --bottom-nav-height: 0px;
    --header-height: 95px;
    --header-bg-color: #fff !important;
    --header-text-color: #fff;
    --footer-bg-color: #f5f8f7;
    --footer-text-color: #102e32;
    --footer-font-size: 13px;
    --page-padding: 30px;
}


.theme-indigo {
    --gradient-offset: #0D0728;
    --gradient-end: #182664;
    --primary-background-color: #182664;
    --secondary-background-color: #1826641a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #182664cc;
    --border-color: #18266480;
}  /* 1st  - blue*/

.theme-mustard-brown{
    --gradient-offset: #BD8220;
    --gradient-end: #C27800;
    --primary-background-color: #C27800;
    --secondary-background-color: #c278001a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #c27800cc;
    --border-color: #c2780080;
}

.theme-la-salle-green{
    --gradient-offset: #07280E;
    --gradient-end: #09742D;
    --primary-background-color: #09742D;
    --secondary-background-color: #09742d1a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #09742dcc;
    --border-color: #09742d80;
} /* 3rd  - green*/

.theme-falu-red{
    --grdient-offset: #280707;
    --grdient-end: #8E1818;
    --primary-background-color: #8E1818;
    --secondary-background-color: #8e18181a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #8e1818cc;
    --border-color: #8e181880;
} /* 4th  - red*/

.theme-dark-yellow{
    --gradient-offset: #282507;
    --gradient-end: #8E8A18;
    --primary-background-color: #8E8A18;
    --secondary-background-color: #8e8a181a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #8e8a18cc;
    --border-color: #8e8a1880;
}  /* 2nd  - yellow*/

.theme-forest-green{
    --gradient-offset: #07280E;
    --gradient-end: #188E32;
    --primary-background-color: #188E32;
    --secondary-background-color: #188e321a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #188e32cc;
    --border-color: #188e3280;
}

.theme-mix-1{
    --gradient-offset: #2D00F8;
    --gradient-end: #7072D8;
    --primary-background-color: #0064F1;
    --secondary-background-color: #0064f11a;
    --theme-primary-text-color: #fff;
    --secondary-text-color: #000000d0;
    --line-color: #0064f1cc;
    --border-color: #0064f180;
}
